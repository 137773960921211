import * as React from "react";
import Layout from "../components/layout.js";

export default function SuccessPage() {
  return (
    <Layout title="Contact us" description="Contact The Design Group">
      <p>Thanks for your interest in the Design Group!</p>
    </Layout>
  );
}
